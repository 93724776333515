export const getPriceLines = ({trayecto, prices, visitas}) => {
  if (!trayecto) return []
  const money = trayecto.primaryMoney.alphabeticCode
  return prices/*.filter(price => price.price>0)?*/.map((price, index) => {
    const basePrice = Number((price.price + price.price * trayecto.tax.value).toFixed(2))
    const quantity =  visitas.vis[index]
    return {
      id: price.id,
      disabled: price.price<=0,
      name: price.passengerCategory.name,
      quantity,
      description: price.passengerCategory.description,
      basePrice: basePrice + ` ${money}` ,
      total: (basePrice * quantity).toFixed(2) + ` ${money}`
    }
  }) || []
}

export const getExtraLines = ({trayecto, visitas}) => {
  if (!trayecto) return []
  const money = trayecto.primaryMoney.alphabeticCode
  return trayecto.extras.map((extra, index) => {
    const basePrice = Number((extra.price + extra.price * extra.tax.value).toFixed(2))
    const quantity = visitas.extrasEdit?.quantityExtras? visitas.extrasEdit?.quantityExtras[index] : 0 || 0
    return {
      id: extra.id,
      disabled: extra.price<=0,
      name: extra.name,
      quantity,
      description: extra.description,
      basePrice: basePrice + ` ${money}`
    }
  })
}

export const initVis = ({trayecto, prices, quantityExtras}) => {
  const auxVis: number[] = []
  const auxPrice: number[] = []
  const auxPriceNet: number[] = [];
  const auxNames: string[] = []
  const indices: number[] = []
  prices.forEach((precio) => {
    auxVis.push(0)
    auxPrice.push(0.00)
    auxPriceNet.push(0.00);
    auxNames.push(precio.passengerCategory.name)
    indices.push(precio.passengerCategory.id)
  })

  let auxQuantityExtras: number[] = []
  let auxPriceExtras: number[] = []
  let auxNamesExtras: string[] = []
  let auxTaxExtras: number[] = []
  if (trayecto.extras) {
    trayecto.extras.forEach((extra) => {
      auxQuantityExtras.push(0)
      auxPriceExtras.push(0.00)
      auxTaxExtras.push(extra.tax.value)
      auxNamesExtras.push(extra.name)
    })
  }
  
  return {
    names: auxNames,
    indices,
    vis: auxVis,
    price: auxPrice,
    base: 0.00,
    total: 0.00,
    totalNet: 0.00,
    totalDiscount: 0.00,
    disabledButtons: false,
    extras: [],
    extrasEdit: {
      names: auxNamesExtras,
      prices: auxPriceExtras,
      taxes: auxTaxExtras,
      quantityExtras
    },
    discount: null
  }
}

export const getMininimumPassengerREach = ({prices}) => {
  let result = true
  for (const price of prices) {
    if (price.passengerCategory.minToReserve && price.price > 0) {
      result = false
      break
    }
  }
  return result
}