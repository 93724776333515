import React, {useState, useEffect, useRef} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import '../../styles/style.css';
import Moment from 'moment';
import 'moment/locale/es'
import validator from 'validator';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import {
  EventName,
  getDominio,
  getOtherDominio,
  getPasarelaPago,
  modalError,
  publish,
  subscribe,
  unsubscribe
} from '../Payment/Request'
import {HeaderView} from '../../components/HeaderView'
import Cart from '../../models/Cart'
import {IPasarelaPago} from '../../models/PasarelaPago'
import {deleteCart, deleteItemFromCart2, getCart, getClientData} from './Request'
import {CartItem} from './CartItem'
import {FormDataBooking} from './FormDataBooking'
import CartTotal from './CartTotal';
import {FooterView} from 'components/FooterView';
import {MultiSafePay} from 'Modules/Payment/MultiSafePay';
import {TrustMyTravel} from 'Modules/Payment/TrustMyTravel';
import Swal from 'sweetalert2';


import {Greenpay} from 'Modules/Payment/Greenpay';
import "../../components/Greenpay.css";

import {Stripe} from 'Modules/Payment/Stripe';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from 'components/CheckoutForm';
import {loadStripe} from '@stripe/stripe-js/pure';
import * as Sentry from "@sentry/react";
import {PaynoPain} from 'Modules/Payment/PaynoPain';
import {useInitIdioma} from 'customHooks/useInitIdioma';
import {Credomatic} from 'Modules/Payment/Credomatic';
import queryString from 'query-string'
import {Request} from '../Afiliate/Request'
import {PaymentGateway} from '../Payment/paymentGateway'
import {useScript} from '../../hooks/useScript'

interface CartParams {
  stripe?: any;
  public?: string;
  data?: string;
}

type CartProps = RouteComponentProps<CartParams>;

interface Props extends CartProps {
  embebed?: boolean
}

export const CartPage: React.FC<Props> = (props: Props) => {

  const publicToken = props.match.params.public;
  const history = useHistory();
  const {idioma, t, i18n} = useInitIdioma(publicToken) // Custom Hook

  // TODO mejor recibirlo por endpoint??
  const PAYNOPAIN: IPasarelaPago = {
    id: 1, name: 'PAYNOPAIN'
  }

  const TRUSTMYTRAVEL: IPasarelaPago = {
    id: 2, name: 'TRUSTMYTRAVEL'
  }

  const MULTISAFEPAY: IPasarelaPago = {
    id: 3, name: 'MULTISAFEPAY'
  }

  const GREENPAY: IPasarelaPago = {
    id: 4, name: 'GREENPAY'
  }

  const STRIPE: IPasarelaPago = {
    id: 5, name: 'STRIPE'
  }

  const CREDOMATIC: IPasarelaPago = {
    id: 6, name: 'CREDOMATIC'
  }


  let uuid;
  try {
    uuid = localStorage.getItem('tokenTicket');
  } catch (e) {
    history.push(`/${publicToken}/cookies`)
  }

  const [cart, setCart] = useState<Cart>();
  const [dataIncorrecto, setDataIncorrecto] = useState<boolean>(false);
  const [emailIncorrecto, setEmailIncorrecto] = useState<boolean>(false);
  const [telefonoIncorrecto, setTelefonoIncorrecto] = useState<boolean>(false);
  const [openDataProblemPopup, setOpenDataProblemPopup] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [money, setMoney] = useState<string>();
  const [clientName, setClientName] = useState<string>('');
  const [dominio, setDominio] = useState<string>();
  const [pasarelaPago, setPasarelaPago] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true)
  const [tokenTicketDeleted, setTokenTicketDeleted] = useState<boolean>(false)
  const [checked, setChecked] = React.useState(false);
  const [client, setClient] = React.useState<any>();
  //const [existsTerms, setExistsTerms] = useState<boolean>(false) ;
  let existsTerms = false;
  const [loadedExists, setLoadedExists] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<any>(undefined)
  const [numero, setNumero] = useState<any>(undefined)

  const [openGreenPay, setOpenGreenPay] = useState<boolean>(false)
  const [dataGreenpay, setDataGreenpay] = useState<any>(undefined)
  const [openStripe, setOpenStripe] = useState<boolean>(false)
  const [dataStripe, setDataStripe] = useState<any>()
  const [stripePromise, setStripePromise] = useState<any>()

  const [adding, setAdding] = useState<boolean>(undefined);
  const [additionalRequiredInformation, setAdditionalRequiredInformation] = useState<boolean>(false)
  const [telephoneFormat, setTelephoneFormat] = useState<string>()
  const [isTransport, setIsTransport] = useState<boolean>(false)
  const [placeHolderAdditional, setPlaceholderAdditional] = useState()

  // TODO de momento a código duro, en el backoffice se configurarán más campos
  const items = [
    {name: 'name', required: true, placeholder: `${t("name")}`},
    {name: 'surname', required: true, placeholder: `${t("surname")}`},
    {name: 'telephone', required: true, placeholder: `${t("phone")}`},
    {name: 'email', required: true, placeholder: `${t("email")}`},

  ]

  if (isTransport) {
    items.push(
      {name: 'pickUpLocations', required: true, placeholder: `${t("pickUpLocations")}`},
      {name: 'dropOffLocations', required: true, placeholder: `${t("dropOffLocations")}`},
      {name: 'flightInfo', required: true, placeholder: `${t("flightInfo")}`},
    )
  } else {
    items.push(
      {
        name: 'additionalInformation',
        required: additionalRequiredInformation,
        placeholder: placeHolderAdditional && placeHolderAdditional[idioma] !== '' ? placeHolderAdditional[idioma] :
          `${t("additionalInformation")}`
      }
    )
  }

  const initialDevelop = {
    name: '',
    surname: '',
    telephone: '',
    email: '',
    pickUpLocations: '',
    dropOffLocations: '',
    flightInfo: '',
    additionalInformation: ''
  }

  // const [dataBooking, setDataBooking] = useState<{[name: string]: string}>(dataBookingInitialState)
  const [dataBooking, setDataBooking] = useState<{ [name: string]: string }>(initialDevelop)

  let additional_info = "";
  const [url_post, setUrl_post] = useState<any>();

  const [openCreditCard, setOpenCreditCard] = useState<any>(false) // Para la pasarela de pago Credomatic
  const [credomaticInfo, setCredomaticInfo] = useState<any>({})
  const [additional_info_credomatic, setAdditinal_info_credomatic] = useState<any>()
  const [a, setA] = useState<any>()
  const [errorA, setErrorA] = useState<boolean>(false)

  const [expired, setExpired] = useState<boolean>(false)

  const isCredomatic = pasarelaPago === 'CREDOMATIC'

  // TODO comentado para pruebas, baseURLTicando es produccion
  //const urlBasePost = `${baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`
  const urlBasePost = `${process.env.REACT_APP_baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}${props.embebed? '&destine=civitrip' : ''}`
  // let url_post = `${baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`

  // TODO ¿por seguridad mejor rellenarlo en el back?
  let url_ok_base = "https://ticket.ticando.net/";
  let url_ko = ''
  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
    url_ko = `https://${process.env.REACT_APP_SUBDOMINIO_DEV}/${publicToken}/cart/`;
  } else if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    url_ko = `https://${process.env.REACT_APP_SUBDOMINIO_PROD}/${publicToken}/cart`
  }


  const listener = (data) => {
    setAdding(false)
  }

  /*useScript({
    src: pasarelaPago === TRUSTMYTRAVEL.name ? `https://payment.tmtprotects.com/tmt-payment-modal.3.6.1.js` : null,
    checkForExisting: true,
  })*/

  useScript(props.embebed ?
      `https://dev.civitrip.com/iframeDisponibility.css` : null,
    {
      removeOnUnmount: true,
      //crossOrigin: 'anonymous',
      referrerPolicy: 'no-referrer'
    }
  )

  useScript(props.embebed ?
      `https://dev.civitrip.com/iframeCart.css` : null, {
      removeOnUnmount: true,
      // crossOrigin: 'anonymous', 
      referrerPolicy: 'no-referrer'
    }
  )
  
  useScript(pasarelaPago === TRUSTMYTRAVEL.name ?
      'https://payment.tmtprotects.com/tmt-payment-modal.3.6.1.js' : null,
    {
      removeOnUnmount: true
    }
  )
  
  const contactRef = useRef<any>({})
  const contact: any = contactRef.current
  useEffect(() => {
    if (props.embebed) {
      const parsed = queryString.parse(window.location.search)
      console.log('parsed.email: ', parsed.email)
      contact.name = parsed?.name
      contact.surname = parsed?.surname
      contact.email = parsed?.email
      contact.telephone = parsed?.telephone
      contact.civitripUser_id = parsed?.civitripUserId
      contact.language = parsed?.language
    }
  })
  
  useEffect(() => {

    if (cart) {
      const _hasItemsExpired = hasItemsExpired(cart)
      setExpired(_hasItemsExpired)
    }

  })

  useEffect(() => {
    const parsed = queryString.parse(window.location.search)
    if (parsed?.at) {
      Request.getAfiliate(publicToken, parsed.at)
        .then(value => {
          setA(value)
        })
        .catch(reason => {
          modalError(
            reason.code,
            'Contacte con el administrador de la página. Gracias y disculpe las molestias',
            () => {
              setErrorA(true)
              // history.push(`/${publicToken}/error/${reason.code}?at=${parsed.at}`)
            }
          )
        })
    }
  }, [])

  useEffect(() => {
    subscribe(EventName.OnFinish, listener)
    return () => {
      unsubscribe(EventName.OnFinish, listener)
    }
  }, [])
  const listenerOnticketDeleted = (data) => {
    setCart(null)
    // envío mensaje de items de carro
    console.log('enviando mensaje carrito')
    
    const toSend = {
      type: 'cartItems',
      value: 0
    }
    window.parent.postMessage(JSON.stringify(toSend), '*')
    
  }

  useEffect(() => {
    subscribe(EventName.OnFinish, listenerOnticketDeleted)
    return () => {
      unsubscribe(EventName.OnFinish, listenerOnticketDeleted)
    }
  }, [])


  useEffect(() => {
    if (!client) {
      getClientData(publicToken).then(res => {
        setClient(res.client)

        // Obtengo la clave publico de Stripe
        if (res.PUBLISHED_APIKEY_STRIPE) {
          setStripePromise(loadStripe(res.PUBLISHED_APIKEY_STRIPE));
        }

        if (JSON.parse(res.client.terms) && JSON.parse(res.client.terms).custom && JSON.parse(res.client.terms).custom[idioma] !== "" && JSON.parse(res.client.terms).custom[idioma] !== "<p></p>\\n") {
          existsTerms = true;
        } else {
          existsTerms = false;
        }
        setLoadedExists(true)
      })
    }
  }, [client])

  useEffect((() => {
    i18n.changeLanguage(idioma);
    let error = new URLSearchParams(window.location.search).get("error")
    if (error) modalError('error')

    if (!cart) {
      Moment.locale('es');
      if (uuid) {
        getCart(uuid, props.embebed? 'opDXLdbBtYTCOdvtOamctbkBsHcssCehriFELgFFFYHTCCNRcAsXbdYUkBMdDrLuZQxkwD' : publicToken)
          .then((res) => {
            if (res.code && res.code === 433) { // 433: carrito ya cobrado
              try {
                localStorage.removeItem('tokenTicket')
              } catch (e) {
                console.log(e)
              }
            }
            if (res.statusCode === 401) {
              Sentry.captureException(new Error("CartPage - Cliente no encontrado"));
              modalError(t("Client not found"))
              return null
            } else if (res.statusCode === 404) {
              Sentry.captureException(new Error("CartPage - Cart"));
              modalError(t("occursError"))
              return null
            }
            setLoading(false);
            if (!res.error) {


              let aux_additionalrequiredinformation = false
              let aux_isTransport = false
              let _placeholderAdditional
              
              res.cartTickets.forEach((cartTicket => {
                if (cartTicket.additionalrequiredinformation) {
                  aux_additionalrequiredinformation = true
                }
                if (cartTicket.ticket.transferService) {
                  aux_isTransport = true
                }
                if (cartTicket.placeholderFormDataBooking) {
                  _placeholderAdditional = cartTicket.placeholderFormDataBooking
                }
              }))

              res.cartCollaboratorTickets.forEach((cartCollaboratorTicket => {
                if (cartCollaboratorTicket.additionalrequiredinformation) {
                  aux_additionalrequiredinformation = true
                }
                if (cartCollaboratorTicket.ticket.transferService) {
                  aux_isTransport = true
                }
              }))
              setIsTransport(aux_isTransport)
              setAdditionalRequiredInformation(aux_additionalrequiredinformation)
              if (_placeholderAdditional) {
                setPlaceholderAdditional(_placeholderAdditional)
              }

              // TODO Código duro packetPriceNet
              res.cartPacketTickets.forEach(cartPacketTicket => {
                cartPacketTicket.packetTicket.collaboratorTickets.forEach(collaboratorTicket => {
                  collaboratorTicket.stretch.prices.forEach(price => {
                    price.packetPriceNet = +((price.priceNet - price.priceNet * 0.1).toFixed(4))
                  })
                })
              })
              setCart(res);
              // envío mensaje de items de carro
              console.log('enviando mensaje carrito')
              const toSend = {
                type: 'cartItems',
                value: getNumberItemsCart(res)
              }
              window.parent.postMessage(JSON.stringify(toSend), '*')
              setMoney(res.money)
              setClientName(res.clientName)
              if (!contact && res.contact) {
                const temp = res.contact
                // teléfono da error si se le mantiene el valor al necesitar country para la validación
                temp.telephone = res.contact.country? res.contact.telephone : ''
                setDataBooking(temp)
              } else {
                if (props.embebed) {
                  setDataBooking(contact)
                }
              }
              localStorage.setItem('clientName', res.clientName)
            }
          })
          .catch(reason => {
            console.log('error: ', reason)
          })
      } else {
        setLoading(false)
      }
    } else {
      setMoney(cart.money)
      setClientName(cart.clientName)
      const subtotal = getTotal(cart)
      setTotalAmount(subtotal);
    }

    /*if(!clientName) {
        getClientName(+ticando_id).then((res) => {
            setClientName(res[0].Nombre);
        });
    }*/
    return () => {
      window.removeEventListener('storage', () => {

      })
    }

  }), [cart, totalAmount, money, clientName, deleting/*uuid, cart, clientName, pasarelaPago, publicToken, dataBooking*/]);

  useEffect(() => {
    if (!pasarelaPago) {
      getPasarelaPago(publicToken).then((res) => {
        setPasarelaPago(res[0].name);
      }).catch(e => {
        Sentry.captureException(new Error("CartPage - errorGetPasarela " + e));
        // history.push(`/${publicToken}/error/${t("errorGetPasarela")}`)
      });
    }
  }, [pasarelaPago])

  useEffect(() => {
    if (!dominio) {
      let uuid_dmn = localStorage.getItem('uuid_dmn')
      let dominio_origin = dominio
      if (uuid_dmn && uuid_dmn !== 'dmn') { // Hay uuid_dmn, por lo que se obtiene la url de la tabla urls_clientes
        getOtherDominio(publicToken, uuid_dmn).then(r => {
          setDominio(JSON.parse(r).url)
        })
      } else { // No hay uuid_dmn, se obtiene el dominio principal como hasta ahora
        getDominio(publicToken).then((res) => {
          setDominio(res)
        }).catch(e => {
          Sentry.captureException(new Error("CartPage - getDominio"));
          // history.push(`/${publicToken}/error/Ha ocurrido un error - getDominio`)
        })
      }
    }
  }, [dominio])

  useEffect(() => {
    const storageListener = (e) => {
      if (e.key === 'tokenTicket' && e.newValue === null) {
        setTokenTicketDeleted(true)
        modalError(t("paidCart"))
        setCart(null)
        // envío mensaje de items de carro
        console.log('enviando mensaje carrito')
        
        const toSend = {
          type: 'cartItems',
          value: 0
        }
        window.parent.postMessage(JSON.stringify(toSend), '*')
      }
    }
    window.addEventListener('storage', storageListener);
    return () => {
      window.removeEventListener('storage', storageListener)
    }
  }, [])

  const closeClickDataProblemHandler = () => {
    setOpenDataProblemPopup(false);
  };

  const handleOpenStripe = () => {
    publish(EventName.OnFinish, null)
    setOpenStripe(false)
  };

  const handleChangeCheked = () => {
    setChecked(!checked);
  };

  const clickTerminos = () => {
    Swal.fire({
      title: `${t('termsConditions')}`,
      html: JSON.parse(client.terms).custom[idioma],
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      }
    })
  }

  const handleChange = (name: string, value: string) => {
    const temp = {...dataBooking}
    temp[name] = value
    setDataBooking({
      ...temp
    })
  }

  const handleDeleteCart = () => {
    deleteCart(uuid, publicToken).then(res => {
      // Ha ido bien
      if (res.code === 200) {
        setCart(undefined)
        // envío mensaje de items en el carro
        console.log('enviando mensaje carrito')
        
        const toSend = {
          type: 'cartItems',
          value: 0
        }
        window.parent.postMessage(JSON.stringify(toSend), '*')
        
      } else if (res.code === 433) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: t('cartPaid'),
        })
      } else if (res.code === 420) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: t('cartnoExists'),
        })
      }
    });

  }

  const handleDeleteItem = (numero) => {
    // Control de errores hecho con el else
    setDeleting(true)
    setNumero(numero)
    deleteItemFromCart2(numero, publicToken).then((response: { result: string, code: number, message: string }) => {
      setDeleting(false)
      if (response.code === 414) {
        Sentry.captureException(new Error("CartPage - El número de ticket no existe"));
        // history.push(`/${publicToken}/error/${"El número de ticket no existe."}`);
      } else if (response.code > 0) {
        getCart(uuid, publicToken).then((res) => {
          setExpired(false)
          setCart(res);
          // envío mensaje de items en el carro
          console.log('enviando mensaje carrito')

          const toSend = {
            type: 'cartItems',
            value: getNumberItemsCart(res)
          }
          window.parent.postMessage(JSON.stringify(toSend), '*')
          
        });
      } else {
        Sentry.captureException(new Error("CartPage - Delete"));
        // history.push(`/${publicToken}/error/${"Hemos obtenido un error inesperado. - Delete"}`);
      }
    })
  }

  const handlePaymentOk = (data) => {
    history.push(`/${publicToken}/payment/success`)
  }

  const handlePaymentError = (data) => {
    console.log('cartPage handlerError - data: ', data)
    // alert(`se ha producido un error en el pago, por favor inténtelo de nuevo`)
  }

  const sendPayment = async () => {
    let {name, surname, telephone, email} = dataBooking
    setAdding(true)
    let tokenTicket;
    try {
      tokenTicket = localStorage.getItem('tokenTicket');
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
        footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
      })
    }
    if (!tokenTicket) {
      return null;
    }
    // envío datos de contacto
    // sendContact(data)
    const baseURLTicando = process.env.REACT_APP_baseURLTicando
    const request = await fetch(
      `${baseURLTicando}/tokenticket/additionalInfo/${uuid}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-provider': 'creaticket',
          'Authorization': `Bearer ${publicToken}`
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(dataBooking)
      }
    )

    // Controlo que ningun item del carrito haya expirado
    let i = 0;
    let expired = false;
    const tickets = [...cart.cartTickets, ...cart.cartCollaboratorTickets, ...cart.cartPacketTickets, ...cart.cartCollaboratorPacketTickets]
    while (i < tickets.length && !expired) {
      if (tickets[i].expire_at !== null) {
        if (new Date(tickets[i].expire_at) < new Date()) { // Ha expirado
          expired = true;
        }
      } else {
        const treintaMins = new Date(tickets[i].created_at);
        treintaMins.setSeconds(treintaMins.getSeconds() + 30 * 60)
        if (treintaMins < new Date()) {
          expired = true;
        }
      }
      i++;
    }

    /*if (expired) { // Algun elemento está expirado
        Swal.fire({
            title: `${t("expiredItem")}`,
            text: `${t("expiredItemmsg")}`,
            icon: 'error'
        })
        return null;
    }*/

    if (name !== "" && telephone !== "" && email !== "") {
      // telephone = telephone.replaceAll(" ", "")
      telephone = telephone.replace(/\s/g, '')
      //if (!validator.isMobilePhone(telephone.replace(" ", ""), 'any') || !validator.isEmail(email)) {
      if (/*telephone.length < telephoneFormat.length ||*/ !validator.isEmail(email)) {
        setDataIncorrecto(true);
        if (!validator.isEmail(email)) {
          setEmailIncorrecto(true)
        }
        if (telephone.length < telephoneFormat.length) {
          Sentry.captureException(new Error("Teléfono incorrecto " + telephone));
          // setTelefonoIncorrecto(true);
        }
        //  if(!validator.isMobilePhone(telephone.replace(" ", "").replace("(", "").replace(")", "").replace("-", ""), 'any')) setTelefonoIncorrecto(true);
      } else {
        getPasarelaPago(publicToken).then(async (res) => {
          setPasarelaPago(res[0].name);
          if (res[0].name === CREDOMATIC.name) {
            setCredomaticInfo(res[0])
          }
          let pasarela_pago = res[0].name
          if (pasarela_pago) {
            if (cart && publicToken && !cart.message) {
              let numeros: string[] = [];
              let url_ok = `${url_ok_base}?client=${publicToken}&`;
              numeros.forEach((numero) => {
                url_ok += `numeros=${numero}&`;
              });
              url_ok = url_ok.substr(0, url_ok.length - 1);

              const description = `${name} ${surname} - ${telephone} - ${email}`;

              url_ok = `https://ticket.ticando.net/?client=${clientName}&numero=${uuid}`
              let uuid_aux;
              try {
                uuid_aux = localStorage.getItem('tokenTicket')
              } catch (e) {

              }
              additional_info = JSON.stringify({
                //cart: cart, // Para prueba de multisafepay se ha quitado
                url: url_ok,
                uuid: uuid_aux,
                //tokenTicket,
                // description,
                dataBooking
              });

              let body;
              let pasarela: PaymentGateway;
              //pasarela_pago = 'CREDOMATIC'
              switch (pasarela_pago) {
                case PAYNOPAIN.name:
                  pasarela = new PaynoPain(tokenTicket, publicToken, totalAmount, description, name, surname, telephone, email, additional_info, money, cart, t)
                  pasarela.set_url_post(`${urlBasePost}&pasarela=${PAYNOPAIN.name}`);

                  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
                    url_ok = `https://${process.env.REACT_APP_SUBDOMINIO_DEV}/${publicToken}/payment/processing?gw=pnp&uuid=${uuid}`
                    // url_ok = `http://localhost:3002/${publicToken}/payment/processing?gw=pnp&uuid=${uuid}`
                    url_ko = `https://${process.env.REACT_APP_SUBDOMINIO_DEV}/${publicToken}/payment/processing?errorPaynoPain=error`
                    // url_ko = `http://localhost:3002/${publicToken}/payment/processing?errorPaynoPain=error`
                  } else if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
                    url_ok = `https://${process.env.REACT_APP_SUBDOMINIO_PROD}/${publicToken}/payment/processing?gw=pnp&uuid=${uuid}`
                    url_ko = `https://${process.env.REACT_APP_SUBDOMINIO_PROD}/${publicToken}/payment/processing?errorPaynoPain=error`
                  }
                  
                  pasarela.set_url_ok(url_ok)
                  pasarela.set_url_ko(url_ko);
                  (pasarela as PaynoPain).setHistory(history)
                  pasarela.setAt(a?.publicToken)
                  pasarela.pay()
                  /*url_post += `&pasarela=${PAYNOPAIN.name}`;
                  body = mountPaynoPainBody(signature, totalAmount*100, customer_ext_id, additional_info, service_uuid, url_post, url_ok, url_ko, description);

                  getPaymentPage(restult.token).then((result) => {
                  // Redirecciona a la url
                  window.location.href = result;
                  });*/
                  break;

                case MULTISAFEPAY.name:
                  if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
                    url_ok = `https://${process.env.REACT_APP_SUBDOMINIO_DEV}/${publicToken}/payment/processing`
                  } else if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
                    url_ok = `https://${process.env.REACT_APP_SUBDOMINIO_PROD}/${publicToken}/payment/processing`
                  }

                  pasarela = new MultiSafePay(tokenTicket, publicToken, totalAmount, description, name, surname, telephone, email, additional_info, money, cart, t)
                  pasarela.set_url_post(`${urlBasePost}&pasarela=${MULTISAFEPAY.name}`);
                  // pasarela.set_url_post(`${process.env.REACT_APP_baseURL}${process.env.REACT_APP_PREFIX}/pruebaresultMultisafepay?publicToken=${publicToken}`)
                  // url_ok = `http://localhost:3002/${publicToken}/payment/processing`
                  pasarela.set_url_ok(url_ok)
                  pasarela.set_url_ko(url_ko); // En multisafe es cancel_url. Tiene sentido que vuelva otra vez a la página del carrito
                  (pasarela as MultiSafePay).setHistory(history)

                  pasarela.setAt(a?.publicToken)
                  pasarela.pay();
                  break;

                case TRUSTMYTRAVEL.name:
                  pasarela = new TrustMyTravel(publicToken, totalAmount, description, name, surname, telephone, email, cart.channel_id, additional_info, money, dominio, history, t, handlePaymentOk, handlePaymentError, uuid, cart)
                  pasarela.set_url_post(`${urlBasePost}&pasarela=${TRUSTMYTRAVEL.name}`)
                  pasarela.set_url_ok(url_ok)
                  pasarela.set_url_ko('')


                  pasarela.setAt(a?.publicToken)
                  pasarela.pay();
                  break;

                case GREENPAY.name:
                  pasarela = new Greenpay(tokenTicket, publicToken, totalAmount, description, name, surname, telephone, email, additional_info, money, cart, t)

                  pasarela.setAt(a?.publicToken)
                  let res = await pasarela.pay()
                  setDataGreenpay(res)
                  break;

                case STRIPE.name:
                  //setUrl_post(`${urlBasePost}&pasarela=${STRIPE.name}`);
                  //setUrl_post(`${process.env.REACT_APP_baseURL}${process.env.REACT_APP_PREFIX}/pruebaresultStripe`);
                  pasarela = new Stripe(tokenTicket, publicToken, totalAmount, description, name, surname, telephone, email, additional_info, money, cart, t)

                  pasarela.setAt(a?.publicToken)
                  let result = await pasarela.pay()
                  setDataStripe(result);
                  if (result.no_error === false) {
                    modalError(t("errorCreatePayment") + result.error_message)
                  }
                  setOpenStripe(true)
                  break;

                case CREDOMATIC.name:
                  //setCredomaticInfo(res[0])
                  setAdditinal_info_credomatic(additional_info)
                  setOpenCreditCard(true)
                  break;
                default:
                  console.log('Otra pasarela no tratada')
              }
              // setAdding(false)
            } else {
              Sentry.captureException(new Error("CartPage - Carrito vacio"));
              modalError(t("emptyCart"))
              setAdding(false)
            }
          }


        }).catch(e => {
          console.log('algo pasa con la pasarela - e: ', e)
          Sentry.captureException(new Error("CartPage - errorGetPasarela"));
          history.push(`/${publicToken}/error/${t("errorGetPasarela")}`)
        });
      }
    } else {
      setOpenDataProblemPopup(true);
    }
  }

  let cartItems = cart ? [...cart.cartTickets ?? [], ...cart.cartPacketTickets ?? [], ...cart.cartCollaboratorTickets ?? [], ...cart.cartCollaboratorPacketTickets ?? []] : []
  const {name, surname, telephone, email, additionalInformation, pickUpLocations, dropOffLocations, flightInfo} = dataBooking
  let habilitarReservar = !tokenTicketDeleted &&
    checked &&
    uuid &&
    totalAmount !== 0 && (name !== "" && surname !== "" && telephone !== "" && email !== "")

  if (isTransport) {
    habilitarReservar = habilitarReservar && pickUpLocations !== '' && dropOffLocations !== '' && flightInfo !== ''
  } else {
    habilitarReservar = habilitarReservar &&
      ((additionalRequiredInformation && additionalInformation !== undefined && additionalInformation !== "") || !additionalRequiredInformation)
  }

  habilitarReservar = habilitarReservar && !expired

  let carritoBoolean = !tokenTicketDeleted && totalAmount !== 0
  if (!uuid) carritoBoolean = false;


  if (client) {
    existsTerms = JSON.parse(client.terms) && JSON.parse(client.terms).custom && JSON.parse(client.terms).custom[idioma] !== "" && JSON.parse(client.terms).custom[idioma].replace(/\s+/g, '') !== "<p></p>";
  }

  let footer_image: string | { url: string, alt: string, href?: string, size?: string }[][] = 'https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png'
  if (pasarelaPago) {
    if (pasarelaPago === 'TRUSTMYTRAVEL') {
      footer_image = '/logo_ticando_tmt.png'
      footer_image = [
        [
          {url: '/Assets/visa2.svg', alt: 'visa'},
          {url: '/Assets/master-card.svg', alt: 'mastercard'},
          {url: '/Assets/amex2.svg', alt: 'amex'},
          {url: '/Assets/discover_2.svg', alt: 'discover'},
          {url: '/Assets/Trust-Me-ID-UTourSite.png', alt: 'tmt_logo', href: 'https://trustmytravel.com/', size: 'big'},
          {
            url: '/Assets/logo_ticando.svg',
            alt: 'Ticando',
            href: 'https://ticando.net',
            size: 'mid'
          }
        ],
      ]
    } else if (pasarelaPago === 'CREDOMATIC') {
      footer_image = [
        [
          {url: '/Assets/visa.svg', alt: 'visa'},
          {url: '/Assets/maestro.svg', alt: 'maestro'},
          {url: 'https://www.global.jcb/en/common/images/svg/jcb_emblem_logo.svg', alt: 'jcb'},
          {url: 'https://www.dinersclub.com/content/experience-fragments/diners-club/home-header-xf/master/_jcr_content/root/header/image.coreimg.svg/1627886360030/dci-logo-default.svg', alt: 'dinersclub'}
        ],
        [
          {url: 'https://www.baccredomatic.com/themes/custom/bac_theme/images/logo.png', alt: 'bac_logo', href: 'https://www.baccredomatic.com/'},
          {
            url: 'https://res.cloudinary.com/marketingpyme/image/upload/v1672387517/logo_ticando_1.png', 
            alt: 'Ticando',
            href: 'https://ticando.net'
          }
        ]
      ]
    } else if (pasarelaPago === MULTISAFEPAY.name) {
      footer_image = [
        [{url: '/Assets/visa.svg', alt: 'visa'},
          {url: '/Assets/amex.svg', alt: 'amex'},
          {url: '/Assets/mastercard.svg', alt: 'mastercard'},
          {url: '/Assets/maestro.svg', alt: 'maestro'}],
        [{
          url: 'https://www.multisafepay.com/fileadmin/template/img/multisafepay-logo-icon.svg',
          alt: 'multisafepay'
        }, {
          url: 'https://res.cloudinary.com/marketingpyme/image/upload/v1672387517/logo_ticando_1.png',
          alt: 'Ticando'
        }]
      ]
    } else if (pasarelaPago === PAYNOPAIN.name) {
      footer_image = [
        [{url: '/Assets/visa.svg', alt: 'visa'},
          {url: '/Assets/mastercard.svg', alt: 'mastercard'},
        ],
        [{
          url: 'https://paynopain.com/wp-content/uploads/2023/02/PNP_426x120.png',
          alt: 'paynopain'
        }, {
          url: 'https://res.cloudinary.com/marketingpyme/image/upload/v1672387517/logo_ticando_1.png',
          alt: 'Ticando'
        }]
      ]
    }
  }
  /*if (cart && props.calendar && !dataBooking.name) {
    return (<h1>Para test - falta name</h1>)
  }
  if (cart && props.calendar && !dataBooking.surname) {
    return (<h1>Para test - falta surname</h1>)
  }
  if (cart && props.calendar && !dataBooking.telephone) {
    return (<h1>Para test - falta telephone</h1>)
  }*/
  
  return (
    <div className="page-wrapper animated fadeIn">
      {a && <div style={{width: '100%', backgroundColor: 'whitesmoke', display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center'}}>
        <p style={{fontSize: 'small', marginRight: '20px', marginTop: '10px', marginBottom: '10px'}}>{a.name}</p>
      </div>}
      {!props.embebed && <HeaderView publicToken={publicToken} at={a?.publicToken}/>}
      <section className="page-header tour-two tour-list destinations-details">
        <div className="container">

          <div className="row">

            <div className="col-md-8">
              <h3 className="destinations-details__title">{t("bookings")}</h3>
              {
                !loading && loadedExists &&
                <div>
                  <button className="btn btn-underline" onClick={() => handleDeleteCart()}>{t("deleteCart")}</button>
                </div>
              }
              {process.env['REACT_APP_ENVIRONMENT'] === 'dev' && cart && <div>{isTransport ? 'transporte' : ''}</div>}

            </div>
            {process.env['REACT_APP_ENVIRONMENT'] === 'dev' && cart && <div>{cart.token}</div>}
          </div>

          <div className="sidebar__single">
            <div style={{padding: "0px 25px"}}>
              {
                cartItems.map(cartItem => {
                  if (!loading && loadedExists) {
                    return (
                      <CartItem
                        publicToken={publicToken}
                        embebed={props.embebed}
                        money={money}
                        key={cartItem.id}
                        item={cartItem}
                        handleDeleteCartItem={handleDeleteItem}
                        deleting={deleting}
                        numero={numero}
                        t={t}
                        onExpired={(value) => {
                          setExpired(value)
                        }}
                      />
                    )
                  }
                })
              }

              {
                !loading && loadedExists &&
                <CartTotal totalAmount={totalAmount} money={money} t={t}/>
              }

              <FormDataBooking
                adding={adding}
                items={items}
                carrito={carritoBoolean}
                expired={expired}
                datosPersonales={
                  isTransport ?
                    name !== "" && surname !== "" && telephone !== "" && email !== "" && pickUpLocations !== '' && dropOffLocations !== '' && flightInfo !== '' :
                    name !== "" && surname !== "" && telephone !== "" && email !== ""
                }
                handleOnClick={sendPayment}
                handleChange={handleChange}
                values={dataBooking} t={t}
                disabled={!habilitarReservar}
                loading={loading}
                loadedExists={loadedExists}
                publicToken={publicToken}
                client={client}
                checked={checked}
                handleChangeCheked={handleChangeCheked}
                existsTerms={existsTerms}
                clickTerminos={clickTerminos}
                // additionalRequiredInformation={additionalRequiredInformation}
                setTelephoneFormat={setTelephoneFormat}
              />

              {
                isCredomatic && additional_info_credomatic &&
                <Credomatic
                  orderid={cart ? cart.token : ""}
                  credomaticInfo={credomaticInfo}
                  openCreditCard={openCreditCard}
                  setOpenCreditCard={setOpenCreditCard}
                  amount={totalAmount}
                  publicToken={publicToken}
                  currency={cart ? cart.money : ''}
                  additional_info={additional_info_credomatic}
                  client={cart ? cart.clientName : ''}
                />
              }
            </div>
          </div>

          <div className="row">
            <Dialog onClose={() => {
              setDataIncorrecto(false);
              setTelefonoIncorrecto(false);
              setEmailIncorrecto(false);
            }} aria-labelledby="customized-dialog-title" open={dataIncorrecto}>
              <DialogTitle id="customized-dialog-title">{t("incorrect")}</DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  <b>{t("the")} {telefonoIncorrecto && "Teléfono"}{telefonoIncorrecto && emailIncorrecto && " y "}{emailIncorrecto && "Email"} {t("formatNotCorrect")}</b>
                </Typography>
              </DialogContent>
              <DialogActions>
                <button type="button" className="btn btn-primary" onClick={() => {
                  setDataIncorrecto(false);
                  setTelefonoIncorrecto(false);
                  setEmailIncorrecto(false);
                  publish(EventName.OnFinish, null)
                }}>{t("close")}</button>
              </DialogActions>
            </Dialog>

            <Dialog onClose={closeClickDataProblemHandler} aria-labelledby="customized-dialog-title"
                    open={openGreenPay}>
              <DialogActions>
                <button type="button" className="btn btn-primary"
                        onClick={closeClickDataProblemHandler}>{t("close")}</button>
              </DialogActions>
            </Dialog>

            <Dialog onClose={closeClickDataProblemHandler} aria-labelledby="customized-dialog-title"
                    open={openDataProblemPopup}>
              <DialogTitle id="customized-dialog-title">{t("includeNecessary")}</DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  <b>{t("includeNecessarymsg")}</b>
                </Typography>
              </DialogContent>
              <DialogActions>
                <button type="button" className="btn btn-primary"
                        onClick={closeClickDataProblemHandler}>{t("close")}</button>
              </DialogActions>
            </Dialog>

            {
              dataStripe && dataStripe.no_error &&
              <Dialog open={openStripe} fullWidth>
                <Elements options={dataStripe.options} stripe={stripePromise}>
                  <CheckoutForm publicToken={publicToken} cart={cart} setOpenStripe={handleOpenStripe}
                                url_post={url_post} totalAmount={totalAmount} money={money} t={t}/>
                </Elements>
              </Dialog>
            }
          </div>
        </div>
      </section>
      <FooterView urlImage={footer_image}/>
    </div>
  );
}

const getTotal = (cart) => {
  if (!cart) return 0

  // total cartTickets
  let totalS = 0
  for (const cartTicket of cart.cartTickets) {
    totalS += cartTicket.ticket.total
  }

  // total cartCollaboratorTickets
  let totalC = 0
  for (const cartCollaboratorTicket of cart.cartCollaboratorTickets) {
    totalC += cartCollaboratorTicket.ticket.total
  }

  // total cartPackets
  let totalP = 0
  for (const cartPacketTicket of cart.cartPacketTickets) {
    totalP += cartPacketTicket.packetTicket.total
  }

  // total cartCollaboratorPackets
  let totalCP = 0
  for (const cartCollaboratorPacketTicket of cart.cartCollaboratorPacketTickets) {
    totalCP += cartCollaboratorPacketTicket.packetTicket.total
  }

  return Number((totalS + totalC + totalP + totalCP).toFixed(2))
}

const hasItemsExpired = (cart: Cart) => {
  let hasItemsExpired = false
  for (const cartTicket of cart.cartTickets) {
    const expireAt = new Date(cartTicket.expire_at)
    const now = new Date()
    if (expireAt < now) {
      console.log('expired')
      hasItemsExpired = true
      break
    }
  }
  if (hasItemsExpired) {
    return hasItemsExpired
  }

  for (const collaboratorTicket of cart.cartCollaboratorTickets) {
    const expireAt = new Date(collaboratorTicket.expire_at)
    const now = new Date()
    if (expireAt < now) {
      hasItemsExpired = true
      break
    }
  }

  return hasItemsExpired
}

export const getNumberItemsCart = (cart) => {
  if (!cart) return 0
  let result = (cart.cartTickets?.length || 0) + (cart.cartCollaboratorTickets?.length || 0) + (cart.cartPacketTickets?.length || 0)
  return result
}